import { ConveyanceTypes } from '@app/enums/conveyance-types.enum';
import { BeneficiaryApplication } from './beneficiary-application';
import { BeneficiaryAssetDetail } from './beneficiary-asset-detail';
import { Booking } from './booking';
import { ActiveSubsidyContract } from './active-subsidy-contract';
import { BlockingReason } from '@app/enums/financial-advisory.enum';
import { BookingSession } from './booking-session';
import { EligibilityStatus } from '@app/enums';
import { PendingBookingFeeInvoice } from './pending-booking-fee-invoice';
import { MetaverseUser } from './metaverse-user';
import { MetaverseRequestAccess } from './metaverse-request-access';

export class Beneficiary {
  relationships: any = {
    beneficiary_assets_detail: {
      rel: 'has_one',
      model: BeneficiaryAssetDetail,
    },
    beneficiary_application: { rel: 'has_one', model: BeneficiaryApplication },
    active_booking: { rel: 'has_one', model: Booking },
    active_booking_session: { rel: 'has_one', model: BookingSession },
    active_subsidy_contract: { rel: 'has_one', model: ActiveSubsidyContract },
    metaverse_user: { rel: 'has_one', model: MetaverseUser },
    metaverse_request_access: { rel: 'has_one', model: MetaverseRequestAccess },
    applicable_online_lending_booking: { rel: 'has_many', model: Booking },
  };

  beneficiary_application?: BeneficiaryApplication;
  beneficiary_assets_detail?: BeneficiaryAssetDetail;
  active_booking?: Booking;
  active_booking_session?: BookingSession;
  active_subsidy_contract?: ActiveSubsidyContract;
  metaverse_user?: MetaverseUser;
  metaverse_request_access?: MetaverseRequestAccess;
  applicable_online_lending_booking?: Booking[];

  constructor(
    public active?: boolean,
    public allocation_status?: string,
    public city_id?: number,
    public date_of_birth?: string,
    public decrypted_national_id_number?: string,
    public email_address?: string,
    public email_unique?: boolean,
    public email_verified?: number,
    public id?: number,
    public name?: string,
    public national_id_number?: string,
    public national_id_number_type?: string,
    public need_update_contact_info?: boolean,
    public phone_number?: string,
    public phone_number_unique?: boolean,
    public preferred_contact_method?: any,
    public region_id?: number,
    public register_date?: string,
    public review_contact_info_at?: Date,
    public secondary_phone_number?: string,
    public preferred_region_id?: number,
    public preferred_city_id?: number,
    // public preferred_unit_type?: string,
    public preferred_unit_types?: string[],
    public down_payment_amount?: number,
    public personal_savings?: number,
    public title_ar?: string,
    public title_en?: string,
    // public preferred_product?: string,
    public preferred_products?: string[],
    public preferred_project_types?: string[],
    public preferred_district_id?: number,
    public purchase_power?: number,
    public first_name?: string,
    public conveyance_type?: ConveyanceTypes,
    public last_login_date?: Date,
    public extra_infos?: any,
    public booking_token?: string,
    public last_name?: string,
    public has_open_appeal?: boolean,
    public signed_subsidy?: boolean,
    public down_payment?: number,
    public viewed_marketplace_onboarding?: any,
    public is_non_beneficiary?: boolean,
    public is_pending_for_beneficiary?: boolean,
    public pending_booking_fee_invoice?: PendingBookingFeeInvoice,
    public number_of_active_bookings_by_project?: any
  ) {}

  get calculated_purchasing_power_at() {
    return this.extra_infos?.calculated_purchasing_power_at || null;
  }

  get financialAdvisoryBlockingReason() {
    if (!this.beneficiary_application) {
      return BlockingReason.NO_BENEFICIARY_APPLICATION;
    }

    if (!this.active) {
      return BlockingReason.NOT_ELIGIBLE;
    }

    if (this.active_subsidy_contract || this.beneficiary_application?.exist_in_moho_list) {
      return BlockingReason.HAS_ACTIVE_CONTRACT;
    }

    if (this.active_booking) {
      return BlockingReason.HAS_ACTIVE_BOOKING;
    }

    return null;
  }

  get eligibilityStatus() {
    return this.beneficiary_application?.eligible_status;
  }

  get isIneligible() {
    return (
      this.eligibilityStatus === EligibilityStatus.NOT_ELIGIBLE &&
      !this.has_open_appeal &&
      !this.beneficiary_application?.isUnknownStatus
    );
  }

  get isEligible() {
    return this.eligibilityStatus === EligibilityStatus.ELIGIBLE;
  }

  get isEligibleNotInGroupNonBeneficiary() {
    return this.eligibilityStatus === EligibilityStatus.ELIGIBLE && !this.isNonBeneficiary;
  }

  get isNonBeneficiary() {
    return this.is_non_beneficiary;
  }

  get isPendingEligible() {
    return !this.isEligible && !this.isIneligible;
  }

  get isPendingForBeneficiary() {
    return this.is_pending_for_beneficiary;
  }

  totalNonBeneBookingsByProject(projectCode?: string) {
    if (!projectCode) {
      return;
    }
    return this.number_of_active_bookings_by_project?.[projectCode];
  }
}
