import { Developer } from './developer';
import { Project } from './project';
import { ProjectUnit } from './project-unit';
export class UnsubsidizedBooking {
  relationships: any = {
    unit: {
      rel: 'has_one',
      model: ProjectUnit,
    },
    project: {
      rel: 'has_one',
      model: Project,
    },
    developer: {
      rel: 'has_one',
      model: Developer,
    },
  };
  unit?: ProjectUnit;
  project?: Project;
  developer?: Developer;

  constructor(
    public id?: number,
    public status?: string,
    public unit_code?: string,
    public project_id?: number,
    public booking_date?: Date,
    public beneficiary_national_id_number?: string,
    public project_code?: string,
    public project_type?: string,
    public moh_price?: string,
    public other_reason?: string,
    public cancelled_date?: string,
    public cancelled_by?: string,
    public cancel_reason?: string,
    public developer_cr_number?: string,
    public unit_id?: string,
    public unit_type?: string,
    public communication_message?: string,
    public project_name?: string
  ) {}
}
