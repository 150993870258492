import { AuctionStatus, LandType, UnitType, SalesChannel, TargetSegment } from '@app/enums';
import { UnitLegendsDisplay } from '@app/enums';
import { Beneficiary } from './beneficiary';
import { Project } from './project';
import { UnitModel } from './unit-model';

export class ProjectUnit {
  relationships = {
    beneficiary: { rel: 'has_one', model: Beneficiary },
    unit_model: { rel: 'has_one', model: UnitModel },
    project: { rel: 'has_one', model: Project },
  };

  unit_model?: UnitModel;
  beneficiary?: Beneficiary;

  constructor(
    public id?: string,
    public apartment_number?: string,
    public bathroom_count?: number,
    public bedroom_count?: number,
    public block_number?: string,
    public building_number?: string,
    public floor?: string,
    public land_number?: string,
    public model?: string,
    public moh_price?: number,
    public price?: number,
    public sales_channels?: string[],
    public target_segments?: TargetSegment[],
    public unit_code?: string,
    public unit_size?: number,
    public total_area?: number,
    public unit_type?: string,
    public auction_status?: string,
    public starting_price?: string,
    public views_count?: number,
    public project_id?: number,
    public floor_count?: number,

    // lands unit
    public land_type?: LandType,
    public limit_northward?: string,
    public limit_eastward?: string,
    public limit_southward?: string,
    public limit_westward?: string,
    public length_northward?: string,
    public length_eastward?: string,
    public length_southward?: string,
    public length_westward?: string,

    public deed_number?: string,
    public plan_number?: string,

    public min_total_price?: string, // only for ASEC project unit
    public project?: Project, // unit reservation
    public azm_item_code?: string,
    public status?: string,
    public completion_percentage: any = 0
  ) {}

  get isFreeLand() {
    return this.land_type === LandType.FreeStandard || this.land_type === LandType.FreeSpecial;
  }

  get isLand() {
    return this.unit_type === UnitType.LAND;
  }

  get isVilla() {
    return this.unit_type === UnitType.VILLA;
  }

  get isTownhouse() {
    return this.unit_type === UnitType.TOWNHOUSE;
  }

  get isApartment() {
    return this.unit_type === UnitType.APARTMENT;
  }

  get isHouse(): boolean {
    return this.unit_type === UnitType.HOUSE;
  }

  get isAuction() {
    return this.auction_status !== AuctionStatus.NOT_TO_BID;
  }

  get unitUrl() {
    if (this.isLand) {
      return `plots/${this.id}`;
    } else {
      return `units/${this.id}`;
    }
  }

  get onlineSalesChannels() {
    return [SalesChannel.WEB, SalesChannel.MOBILE];
  }

  get offlineSalesChannels() {
    return [SalesChannel.PARTNERS];
  }

  get isOnlineUnit(): boolean {
    return this.sales_channels?.find((sc) => this.onlineSalesChannels.includes(sc as SalesChannel)) ? true : false;
  }

  get isOfflineUnit(): boolean {
    return this.sales_channels?.find((sc) => this.offlineSalesChannels.includes(sc as SalesChannel)) ? true : false;
  }

  get hasWebChannel(): boolean {
    return this.sales_channels?.includes(SalesChannel.WEB) ? true : false;
  }

  get hasMobileChannel(): boolean {
    return this.sales_channels?.includes(SalesChannel.MOBILE) ? true : false;
  }

  get hasPartnersChannel(): boolean {
    return this.sales_channels?.includes(SalesChannel.PARTNERS) ? true : false;
  }

  get isOnlineSalesChannels() {
    return (
      this.sales_channels &&
      this.sales_channels?.length >= 2 &&
      this.sales_channels?.includes(SalesChannel.WEB) &&
      this.sales_channels?.includes(SalesChannel.MOBILE)
    );
  }

  get isOfflineSalesChannels() {
    return this.sales_channels?.includes(SalesChannel.PARTNERS);
  }

  get isOnlineWebSalesChannels() {
    return this.sales_channels?.includes(SalesChannel.WEB);
  }

  get isOnlineAppSalesChannels() {
    return this.sales_channels?.includes(SalesChannel.MOBILE);
  }

  get getSalesChannelsLegend(): UnitLegendsDisplay | '' {
    if (this.isAuction) {
      return '';
    }
    if (this.isOnlineSalesChannels) {
      return UnitLegendsDisplay.ONLINE;
    } else if (this.isOnlineWebSalesChannels) {
      return UnitLegendsDisplay.ONLINE_WEB;
    } else if (this.isOnlineAppSalesChannels) {
      return UnitLegendsDisplay.ONLINE_APP;
    } else if (this.isOfflineSalesChannels) {
      return UnitLegendsDisplay.OFFLINE;
    } else {
      return '';
    }
  }

  get getAuctionLegend(): UnitLegendsDisplay | '' {
    if (!this.isAuction) {
      return '';
    }
    switch (this.auction_status) {
      case 'open':
        return UnitLegendsDisplay.AUCTION_OPEN;
      case 'expired':
        return UnitLegendsDisplay.AUCTION_EXPIRED;
      case 'not_started':
        return UnitLegendsDisplay.AUCTION_NOT_STARTED;
      case 'closed':
        return UnitLegendsDisplay.AUCTION_CLOSED;
      default:
        return '';
    }
  }

  get getSalesChannelsLegendColor() {
    switch (this.getSalesChannelsLegend) {
      case UnitLegendsDisplay.ONLINE:
        return 'online';

      case UnitLegendsDisplay.OFFLINE:
        return 'offline';

      case UnitLegendsDisplay.ONLINE_WEB:
        return 'online-web';

      case UnitLegendsDisplay.ONLINE_APP:
        return 'online-app';

      default:
        return '';
    }
  }

  get onlineOrOfflineText() {
    if (this.isOnlineUnit) {
      return 'online';
    } else if (this.isOfflineUnit) {
      return 'offline';
    } else {
      return '';
    }
  }

  get hasBeneficiaryTargertSegnment() {
    return this.target_segments?.includes(TargetSegment.BENEFICIARY);
  }

  isAvailableUnit(beneficiary?: Beneficiary) {
    if (beneficiary?.isEligibleNotInGroupNonBeneficiary) {
      return this.target_segments?.includes(TargetSegment.BENEFICIARY);
    }

    if (beneficiary?.isNonBeneficiary) {
      return this.target_segments?.includes(TargetSegment.NON_BENE);
    }

    return true;
  }
}
