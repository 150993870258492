import { Auction } from './auction';

export class BidderRegistration {
  relationships = {
    auction_unit: { rel: 'has_one', model: Auction },
  };
  constructor(
    public id?: any,
    public auction_unit_id?: number,
    public beneficiary_national_id_number?: string,
    public sadad_sub_invoicer_id?: number,
    public payment_channel?: string,
    public product_type?: string,
    public sadad_invoice_status?: string,
    public sadad_invoice_number?: string,
    public sadad_invoice_amount?: number,
    public sadad_invoice_created_at?: Date,
    public sadad_invoice_expired_at?: Date,
    public snapshot_data?: any,
    public auction_unit?: Auction,
    public time_left?: string,
    public azm_invoice_expired_at?: string,
    public azm_invoice_amount?: string,
    public azm_invoice_number?: string,
    public payment_status?: string,
    public azm_encrypted_invoice_number?: string
  ) {}
}
