import { Component, OnInit } from '@angular/core';
import { OffplanBannerMessageService } from '@app/base/services/ui/offplan-banner-message.service';
import { BannerMessageInterface } from '@app/interfaces';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-warning-banner-message',
  templateUrl: './warning-banner-message.component.html',
  styleUrls: ['./warning-banner-message.component.scss'],
})
export class WarningBannerMessageComponent implements OnInit {
  warningMessage?: BannerMessageInterface | undefined;

  constructor(private bannerMessageService: OffplanBannerMessageService) {}

  ngOnInit(): void {
    this.bannerMessageService.showWarningBannerMessageSubs.pipe(untilDestroyed(this)).subscribe((bannerData) => {
      this.warningMessage = bannerData;
    });
  }
}
