export class CertifiedContractor {
  relationships: any = {};

  constructor(
    public id?: number,
    public message?: string,
    public code?: string,
    public cr_number?: string,
    public name?: string,
    public name_ar?: string,
    public auth_personal_name?: string,
    public phone_number?: string,
    public membership_number?: number,
    public membership_status?: string,
    public city_en?: string,
    public city_ar?: string,
    public region_en?: string,
    public region_ar?: string,
    public start_date?: string,
    public validity_end?: string,
    public email?: string,
    public logo?: string,
    public latitude?: number,
    public longitude?: number,
    public publish?: boolean,
    public company_size_en?: string,
    public company_size_ar?: string,
    public price_per_meter?: number,
    public category?: string,
    public facility_size?: string,
    public region_id?: number,
    public city_id?: number
  ) {}

  name_by_lang(lang: string) {
    return lang === 'ar' ? this.name_ar : this.name;
  }

  city_by_lang(lang: string) {
    return lang === 'ar' ? this.city_ar : this.city_en;
  }

  region_by_lang(lang: string) {
    return lang === 'ar' ? this.region_ar : this.region_en;
  }
}

export class CertifiedContractorDesignSuggestion extends CertifiedContractor {
  constructor(public estimated_price?: number) {
    super();
  }
}
