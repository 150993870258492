import { AuctionStatus } from '@app/enums';
import { AuctionPaymentDetail } from './auction-payment-detail';

export class Auction {
  relationships = {
    auction_payment_detail: { rel: 'has_one', model: AuctionPaymentDetail },
  };
  constructor(
    public id?: any,
    public unit_code?: string,
    public project_code?: string,
    public status?: AuctionStatus,
    public starting_price?: any,
    public maximum_price?: any,
    public start_at_hijri?: any,
    public end_at_hijri?: any,
    public highest_bidder_name?: string,
    public highest_national_id_number?: string,
    public bidder_total_number?: any,
    public current_price?: number,
    public end_at?: string,
    public start_at?: string,
    public project_name?: string,
    public apply_general_setting?: boolean,
    public auction_fee_amount?: string,
    public auction_fee_flag?: boolean,
    public added_time?: number,
    public targeted_price?: number,
    public is_added_time?: boolean,
    public check_added_time?: boolean,
    public plot_number?: string,
    public block_number?: string,
    public project_id?: string,
    public unit_id?: string,
    public auction_payment_detail?: AuctionPaymentDetail,
    public fixed_increase_numbers?: number[],
    public contract_action_period?: number,
    public pay_final_price_period?: number,
    public auction_setting_snapshot_values?: any
  ) {}
}
export class AuctionSetting {
  relationships = {};
  constructor(
    public id?: any,
    public auctions_activation?: boolean,
    public auction_fee_amount?: string,
    public auction_fee_flag?: boolean,
    public added_time?: number,
    public fixed_increase_numbers?: any[],
    public contract_action_period?: number,
    public pay_final_price_period?: number,
    public active_payment_methods?: any[]
  ) {}
}

export class MyAuction extends Auction {
  constructor(
    public latest_bid_price?: number,
    public latest_bid_submission_time?: string,
    public contract_status?: string,
    public rejected_reason?: string,
    public contract_expired_at?: string,
    public payment_status?: string
  ) {
    super();
  }
}
