import { Injectable } from '@angular/core';
import { SakaniSessionStorageService } from './sakani-session-storage.service';
import { SakaniSession, User } from '@app/models';
import { BehaviorSubject, Observable } from 'rxjs';
import jwt_decode from 'jwt-decode';

@Injectable({ providedIn: 'root' })
export class SakaniSessionService {
  private session: BehaviorSubject<SakaniSession> = new BehaviorSubject<SakaniSession>(new SakaniSession());
  public session$: Observable<SakaniSession> = this.session.asObservable();

  constructor(private sessionStorage: SakaniSessionStorageService) {
    this.restoreSession();
  }

  setSession(user: User): SakaniSession {
    // preserve user_id from current localstorage if user object is beneficiary
    if (this.currentSession?.user?.user_id) {
      user.user_id = this.currentSession.user.user_id;
    }
    const newStoredSession = new SakaniSession(user);
    this.session.next(newStoredSession);
    this.sessionStorage.setStorage(newStoredSession);
    return newStoredSession;
  }

  get isAuthenticated(): boolean {
    return this.session.value && this.session.value.isAuthenticated;
  }

  get currentSession(): SakaniSession {
    return this.session.value;
  }

  clearSession() {
    const emptySession = new SakaniSession();

    this.session.next(emptySession);
    this.sessionStorage.setStorage(emptySession);
    localStorage.removeItem('bookingSession');
    localStorage.removeItem('jwtData');
    localStorage.removeItem('kiosk_authentication');
    sessionStorage.clear();
  }

  get currentUser(): User | undefined {
    return this.getSession()?.user;
  }

  get isSaudiCitizen(): boolean {
    const currentUser = this.getSession()?.user;
    return currentUser?.user_id?.substring(0, 1) === '1';
  }

  private getSession(): SakaniSession {
    return this.session.value;
  }

  // Load session first time after app component init.
  restoreSession() {
    let session: SakaniSession = this.sessionStorage.getStorage();
    if (session && session.user) {
      // remove the property from the object, which might have value. This keep backward compatibility with old sessions.
      delete (session as any).isAuthenticated;
      // override session with class instance
      session = Object.assign(new SakaniSession(), session);
    }
    this.session.next(session);
  }
}
