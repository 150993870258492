export class CmsPopup {
  relationships: any = {};
  constructor(
    public id?: string,
    public description?: string,
    public image?: {
      url: string;
    },
    public title?: string,
    public cancel_text?: string,
    public confirm_text?: string,
    public app_modal_key?: string
  ) {}
}
