<section class="bg-new-brand-p1 footer-section">
  <footer>
    <div class="container mx-auto overflow-hidden pb-3 pt-9 pt-md-11">
      <div class="row">
        <div class="col-md-7">
          <a class="text-decoration-none m-start-1" href="/">
            <img
              src="assets/images/nhc-marketplace/nhc-cityscape-logo.svg"
              class="app-logo"
              height="40"
              alt="NHC logo"
            />
          </a>
          <div class="text-white fs-xs pt-4">
            {{ 'NHC.FOOTER.SUMMARY' | translate }}
          </div>
          <div class="d-flex flex-row gap-2 mt-4 mb-md-0 mb-7">
            <a [href]="socialNetworks?.facebook" class="text-decoration-none" target="_blank">
              <img width="16" height="16" src="assets/icons/socials/nhc/facebook.svg" alt="facebook" />
            </a>
            <a [href]="socialNetworks?.twitter" class="text-decoration-none" target="_blank">
              <img width="16" height="16" src="assets/icons/socials/nhc/x.svg" alt="x" />
            </a>
            <a [href]="socialNetworks?.youtube" class="text-decoration-none" target="_blank">
              <img width="16" height="16" src="assets/icons/socials/nhc/youtube.svg" alt="youtube" />
            </a>
            <!-- <a [href]="socialNetworks?.whatsapp" class="text-decoration-none" target="_blank">
              <img width="16" height="16" src="assets/icons/socials/nhc/whatsapp.svg" alt="" />
            </a> -->
            <a [href]="socialNetworks?.instagram" class="text-decoration-none" target="_blank">
              <img width="16" height="16" src="assets/icons/socials/nhc/instagram.svg" alt="instagram" />
            </a>
            <a [href]="socialNetworks?.snapchat" class="text-decoration-none" target="_blank">
              <img width="16" height="16" src="assets/icons/socials/nhc/snapchat.svg" alt="snapchat" />
            </a>
          </div>
        </div>
      </div>

      <hr class="thick-line text-gray-blue opacity-100" size="auto" />

      <div
        class="d-flex flex-column flex-md-row align-items-md-center w-100 justify-content-between gap-4 flex-sm-column-reverse"
      >
        <div class="text-white fs-xs">
          {{ 'NHC.FOOTER.COPYRIGHT' | translate }}
        </div>
      </div>
    </div>
  </footer>
</section>
