import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { SakaniModalType } from '@app/enums';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  sakaniModalType = SakaniModalType;

  @Input() success = false;
  @Input() type!: SakaniModalType; // display types for message or customized external template
  @Input() headerText!: string; // title of the modal
  @Input() headerParams!: any;
  @Input() secondaryHeaderText!: string; // secondary title of the modal
  @Input() template!: TemplateRef<any>; // content of external template
  @Input() message!: string; // content of message
  @Input() closeButtonText!: string;
  @Input() confirmButtonText!: string;
  @Input() templateClass = 'my-5 mx-5'; // set main-class to customize the modal css content
  @Input() showCloseIcon = false; // show/display close icon in template
  @Input() isCenterAlignment = true; // center layout if modalType = message
  @Input() headerClass = 'd-flex mb-5 align-items-center justify-content-between';
  @Input() closeIconClass = 'svg-icon svg-icon--extra icon-cross-bg cursor-pointer';
  @Input() confirmButtonClasses = 'btn btn-secondary';
  @Input() v5Template: boolean = false;
  @Input() prefixHeaderIconUrl: string = '';

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  closeTemplateModal() {
    this.activeModal.close({ closed: true });
  }

  closeModal() {
    this.activeModal.close({ closed: true });
  }

  confirmModal() {
    this.activeModal.close({ confirmed: true });
  }
}
