export class FinancialInstitution {
  public relationships = {};
  public loan_promise?: number;

  constructor(
    public id?: number,
    public name?: string,
    public realname?: string,
    public cr_number?: string,
    public logo?: string,
    public project_codes?: any[],
    public description?: string,
    public project_membership_phase?: string,
    public offplan_lending?: boolean,
    public self_construction_lending?: boolean,
    public moh_ready_lending?: boolean,
    public market_unit_lending?: boolean,
    public lending_form_url_offplan?: string,
    public lending_form_url_moh_ready?: string
  ) {}
}
