export enum UnitType {
  TOWNHOUSE = 'townhouse',
  APARTMENT = 'apartment',
  VILLA = 'villa',
  DUPLEX = 'duplex',
  FLOOR_THROUGH = 'floor_through',
  LAND = 'land',
  STUDIO = 'studio',
  ROOM = 'room',
  COMPOUND = 'compound',
  TOWER = 'tower',
  GALLERY = 'gallery',
  OFFICE = 'office',
  WAREHOUSE = 'warehouse',
  BOOTH = 'booth',
  CINEMA = 'cinema',
  HOTEL = 'hotel',
  PARKING_SPACE = 'parking_space',
  WORKSHOP = 'workshop',
  ATM = 'atm',
  FACTORY = 'factory',
  SCHOOL = 'school',
  HOSPITAL = 'hospital',
  STATION = 'station',
  FARM = 'farm',
  BUILDING = 'building',
  POWER_STATION = 'power_station',
  REST_HOUSE = 'rest_house',
  COMMUNICATION_TOWER = 'communication_tower',
  HOUSE = 'house',
}
