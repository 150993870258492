import { CmsVoucherSection } from './../../../models/cms-voucher-section';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/base/services/http/api.service';
import { currentLang } from '@app/helpers';
import { Endpoints } from '@app/helpers/endpoints';
import { JsonApi, CmsPopup } from '@app/models';
import { environment } from '@environment/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class CmsService {
  constructor(private api: ApiService) {}

  getCmsMetadata(): Observable<any> {
    return this.api.apiGet(Endpoints.CMS_API_PATH, undefined, {
      lang: currentLang(),
    });
  }

  getVoucherSections(): Observable<any> {
    return this.api
      .apiGet(Endpoints.CMS_VOUCHER_SECTIONS_API_PATH_V1, undefined, {
        lang: currentLang(),
      })
      .pipe(
        map((res: any) => {
          return JsonApi.parseJsonApi(CmsVoucherSection, res.data);
        })
      );
  }

  getCmsPopup(appModalKey: string): Observable<any> {
    return this.api
      .apiGet(`${Endpoints.CMS_POPUP_API_PATH_V1}/${appModalKey}`, undefined, {
        lang: currentLang(),
      })
      .pipe(
        map((res: any) => {
          if (res.data) {
            return JsonApi.parseJsonApi(CmsPopup, res.data);
          } else {
            return {};
          }
        })
      );
  }

  getKioskProjectCodes(): Observable<any> {
    return this.api.apiGet(`${Endpoints.CMS_BASE_PATH}/v1/kiosk/${environment.cmsSiteId}/project_codes`, undefined, {
      lang: currentLang(),
    });
  }
}
