<ng-template #headerMessage>
  <ng-container *ngIf="blockReasonMessage">
    <div class="gap-4 fs-6 col-lg-6 col">
      <div>
        <span class="text-dark" [innerHtml]="blockReasonMessage.firstDescription"></span>
      </div>
    </div>
    <div class="col-12 col-sm-auto justify-content-between">
      <div class="mt-4">
        <!-- Queueing -->
        <ng-container *ngIf="blockReasonMessage.forCase === 'queueInJourney'">
          <button (click)="leaveQueue()" class="btn px-0 border-0 d-flex flex-row cursor-pointer text-secondary">
            <span class="fw-bold m-end-2">{{ blockReasonMessage.buttonText }}</span>
            <span class="svg-icon svg-icon--secondary icon-exit"></span>
          </button>
        </ng-container>
        <!---Queueing other journey-->
        <ng-container *ngIf="blockReasonMessage.forCase === 'queueOutJourney'">
          <div class="row g-0">
            <div class="col-12 col-md-auto">
              <button (click)="continueBooking()" class="btn btn-secondary justify-content-center btn-width-mobile">
                <span class="fw-bold m-end-2">{{ blockReasonMessage.buttonText }}</span>
                <span class="svg-icon svg-icon" [ngClass]="arrowIconClass()"></span>
              </button>
            </div>
            <div class="col-12 col-md-auto mx-3">
              <button (click)="leaveQueue()" class="btn px-0 border-0 d-flex flex-row cursor-pointer text-secondary">
                <span class="fw-bold m-end-2">{{ blockReasonMessage.secondBtnText }}</span>
                <span class="svg-icon svg-icon--secondary icon-exit"></span>
              </button>
            </div>
          </div>
        </ng-container>

        <!--start timer-->
        <ng-container *ngIf="blockReasonMessage.forCase === 'mainJourney'">
          <button (click)="cancelBooking()" class="btn px-0 border-0 d-flex flex-row cursor-pointer text-secondary">
            <span class="fw-bold m-end-2">{{ blockReasonMessage.buttonText }}</span>
            <span class="svg-icon svg-icon--secondary icon-cross"></span>
          </button>
        </ng-container>
        <!---other journey-->
        <ng-container *ngIf="blockReasonMessage.forCase === 'outOfJourney'">
          <div class="row g-0">
            <div class="col-12 col-md-auto">
              <button (click)="continueBooking()" class="btn btn-secondary justify-content-center btn-width-mobile">
                <span class="fw-bold m-end-2">{{ blockReasonMessage.buttonText }}</span>
                <span class="svg-icon svg-icon" [ngClass]="arrowIconClass()"></span>
              </button>
            </div>
            <div class="col-12 col-md-auto mx-3">
              <button (click)="cancelBooking()" class="btn px-0 border-0 d-flex flex-row cursor-pointer text-secondary">
                <span class="fw-bold m-end-2">{{ blockReasonMessage.secondBtnText }}</span>
                <span class="svg-icon svg-icon--secondary icon-cross"></span>
              </button>
            </div>
          </div>
        </ng-container>

        <!--expired out of current-->
        <ng-container *ngIf="blockReasonMessage.forCase === 'expiredOutJourney'">
          <div class="row g-0">
            <div class="col-12 col-md-auto">
              <button (click)="returnToProject()" class="btn btn-secondary justify-content-center btn-width-mobile">
                <span class="fw-bold m-end-2">{{ blockReasonMessage.buttonText }}</span>
                <span class="svg-icon svg-icon" [ngClass]="arrowIconClass()"></span>
              </button>
            </div>

            <div class="col-12 col-md-auto mx-3">
              <button (click)="dismiss()" class="btn px-0 border-0 d-flex flex-row cursor-pointer text-secondary">
                <span class="fw-bold m-end-2">{{ blockReasonMessage.secondBtnText }}</span>
                <span class="svg-icon svg-icon--secondary icon-cross"></span>
              </button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="blockReasonMessage.forCase === 'expiredInJourney'">
          <button (click)="dismiss()" class="btn px-0 border-0 d-flex flex-row cursor-pointer text-secondary">
            <span class="fw-bold m-end-2">{{ blockReasonMessage.buttonText }}</span>
            <span class="svg-icon svg-icon--secondary icon-cross"></span>
          </button>
        </ng-container>
      </div>
    </div>

    <ng-container *ngIf="!blockReasonMessage.forCase">
      <button
        (click)="redirectToOtherPage(blockReasonMessage)"
        class="btn btn-secondary mt-4"
        *ngIf="blockReasonMessage.buttonText"
      >
        <span class="fw-bold m-end-2 fs-6">{{ blockReasonMessage.buttonText }}</span>
        <span class="svg-icon svg-icon" [ngClass]="arrowIconClass()"></span>
      </button>
    </ng-container>
  </ng-container>
</ng-template>

<ng-container *ngIf="bookingSession?.project_type !== 'lands_moh_land'">
  <div
    class="d-flex flex-column banner-message"
    appLayoutDirection
    *ngIf="blockReasonMessage"
    [ngClass]="blockReasonMessage.bgClass || 'bg-light-yellow'"
  >
    <div
      (click)="!blockReasonMessage.disabledCollapsed ? callCollapse.toggle() : null"
      [attr.aria-expanded]="!isCollapsed"
      aria-controls="collapse"
      class="d-flex py-2 px-3 align-items-center text-decoration-none container"
      [class.cursor-pointer]="!blockReasonMessage.disabledCollapsed"
    >
      <div class="d-flex py-3 justify-content-between w-100 align-items-center">
        <div class="d-flex align-items-center">
          <span class="svg-icon svg-icon--secondary" [ngClass]="blockReasonMessage.icon"></span>
          <h5 [ngClass]="'fs-6 mx-3 lh-base mb-0 fw-bold'" [innerHtml]="blockReasonMessage.title"></h5>
          <div class="box-countdown" *ngIf="bookingSession && !isBookingExpired && durationTimer">
            <app-countdown
              *ngIf="durationTimer"
              [countdownClass]="'text-white fw-bold'"
              (countdownExpired)="bookingTimedOut($event)"
              [durationInSeconds]="durationTimer"
              (currentTimmer)="updateCurrentTimmer($event)"
            >
            </app-countdown>
          </div>
        </div>
        <div *ngIf="!blockReasonMessage.disabledCollapsed">
          <span
            class="svg-icon icon-chevron-up"
            [class.svg-icon--rotate-180]="isCollapsed"
            [ngClass]="iconClass ? iconClass : 'svg-icon--secondary'"
          >
          </span>
        </div>
      </div>
      <ng-container *ngTemplateOutlet="subHeader"> </ng-container>
    </div>

    <div
      class="position-absolute w-100 top-100 start-0"
      [ngClass]="blockReasonMessage.bgCollapsedClass || 'banner-light-yellow'"
    >
      <div #callCollapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" class="container">
        <div class="mb-3 mx-5 px-2">
          <ng-container *ngTemplateOutlet="headerMessage"></ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #forWordToPageTpl>
  <p>{{ forwardTo.content }}</p>
  <a
    class="text-secondary d-flex gap-2 text-decoration-none align-items-center cursor-pointer"
    (click)="navigateToActionUrl()"
  >
    <span>{{ forwardTo.actionText }}</span>
    <i appLayoutDirection class="svg-icon auto-rotate icon-arrow-right svg-icon--secondary"></i>
  </a>
</ng-template>

<app-warning-banner-message></app-warning-banner-message>
