<div appLayoutDirection [ngClass]="templateClass" *ngIf="!v5Template; else v5Block">
  <ng-container *ngIf="type === sakaniModalType.TEMPLATE">
    <div [ngClass]="headerClass">
      <div>
        <p class="h4 mb-0">{{ headerText | translate: headerParams }}</p>
        <span *ngIf="secondaryHeaderText" class="text-mid-gray">{{ secondaryHeaderText | translate }}</span>
      </div>
      <span *ngIf="showCloseIcon" [ngClass]="closeIconClass" (click)="closeTemplateModal()"></span>
    </div>
    <ng-container *ngTemplateOutlet="template; context: { modal: activeModal }"></ng-container>
  </ng-container>

  <ng-container *ngIf="type === sakaniModalType.MESSAGE">
    <div class="d-flex flex-column gap-3" [class.align-items-center]="isCenterAlignment">
      <p class="h4 mb-0 text-secondary">{{ headerText | translate }}</p>
      <div class="font-weight-normal mr-3" [innerHtml]="message | safe: 'html'"></div>
      <div class="d-flex flex-row gap-3 justify-content-start">
        <button *ngIf="closeButtonText" type="button" class="btn border-secondary" (click)="closeModal()">
          {{ closeButtonText | translate }}
        </button>
        <button *ngIf="confirmButtonText" type="button" [ngClass]="confirmButtonClasses" (click)="confirmModal()">
          {{ confirmButtonText | translate }}
        </button>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #v5Block>
  <div class="d-flex d-md-none align-items-center justify-content-center pt-3">
    <div class="mobile-indicator" (click)="closeModal()" [tabindex]="-1"></div>
  </div>
  <div class="modal-header border-0 pt-3 pt-md-5 px-5 pb-0" [ngClass]="headerClass">
    <img class="px-2" *ngIf="prefixHeaderIconUrl !== ''" src="{{ prefixHeaderIconUrl }}" alt="" />
    <h3 class="m-0">{{ headerText | translate: headerParams }}</h3>
    <button class="d-none d-md-block btn-close" (click)="closeModal()" [tabindex]="-1"></button>
  </div>
  <div class="modal-body position-relative p-0">
    <div class="font-weight-normal px-5 pt-5" [innerHtml]="message | safe: 'html'"></div>
    <!-- <p class="px-5">{{ message | translate }}</p> -->
  </div>
  <div class="modal-footer p-5 d-flex gap-3 justify-content-start">
    <button
      *ngIf="closeButtonText"
      type="button"
      class="btn btn-secondary flex-grow-1 flex-md-grow-0"
      (click)="closeModal()"
    >
      {{ closeButtonText | translate }}
    </button>
    <button *ngIf="confirmButtonText" type="button" [ngClass]="confirmButtonClasses" (click)="confirmModal()">
      {{ confirmButtonText | translate }}
    </button>
  </div>
</ng-template>
