import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    data: { title: 'PAGE_TITLE.NHC' },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'marketplace',
      },
      {
        path: 'marketplace',
        loadChildren: () =>
          import('@app/pages/nhc-page/nhc-marketplace-page/nhc-marketplace-page.module').then(
            (m) => m.NhcMarketplacePageModule
          ),
      },
      {
        path: 'mega-project',
        loadChildren: () =>
          import('@app/pages/nhc-page/nhc-mega-project-page/nhc-mega-project-page.module').then(
            (m) => m.NhcMegaProjectPageModule
          ),
      },
      {
        path: 'offplan-projects',
        loadChildren: () =>
          import('@app/pages/nhc-page/nhc-project-pages/offplan-page/offplan-page.module').then(
            (m) => m.OffplanPageModule
          ),
      },
      {
        path: 'starting-offplan-projects',
        loadChildren: () =>
          import('@app/pages/nhc-page/nhc-project-pages/offplan-page/offplan-page.module').then(
            (m) => m.OffplanPageModule
          ),
      },
      {
        path: '**',
        pathMatch: 'prefix',
        redirectTo: 'error',
      },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class NhcRoutingModule {}
