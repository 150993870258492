import { EligibilityStatus } from '@app/enums';
import { Beneficiary, Project, ProjectUnit } from '@app/models';

export const getUnitPriceInfo = (unit?: ProjectUnit, project?: Project, beneficiary?: Beneficiary): any => {
  const marketPrice = +(unit?.price || 0);

  if (unit?.isFreeLand) {
    return {
      price: 0,
      label: 'UNIT_DETAIL.UNIT_DESCRIPTION.UNIT_PRICE',
    };
  }

  if (project?.isOffPlan && (!beneficiary || beneficiary?.isNonBeneficiary)) {
    return {
      price: marketPrice,
      label: 'MARKETPLACE.SINGLE_UNIT.DETAILS.MARKET_PRICE',
    };
  }

  if (project?.isAdvancedSelfConstruction) {
    return {
      price: +(unit?.min_total_price || 0),
      label: 'UNIT_DETAIL.UNIT_DESCRIPTION.UNIT_PRICE',
    };
  }

  return { price: +(unit?.moh_price || 0), label: 'MARKETPLACE.SINGLE_UNIT.DETAILS.BENEFICIARY_PRICE' };
};
