import { EsriMapGeometryType } from '@app/enums';
import { Graphic } from '@app/shared/esri.module';
export class EsriMapLayers {
  public static readonly ONLINE_LAYER = {
    name: 'layer-online',
    type: 'simple-fill',
    color: 'rgb(0, 172, 131)',
    style: 'solid',
    outline: {
      width: 0.5,
      color: [255, 255, 255],
    },
  };

  public static readonly ONLINE_WEB_LAYER = {
    name: 'layer-online',
    type: 'simple-fill',
    color: 'rgb(37, 49, 107)',
    style: 'solid',
    outline: {
      width: 0.5,
      color: [255, 255, 255],
    },
  };

  public static readonly ONLINE_APP_LAYER = {
    name: 'layer-online',
    type: 'simple-fill',
    color: 'rgb(138, 151, 155)',
    style: 'solid',
    outline: {
      width: 0.5,
      color: [255, 255, 255],
    },
  };

  public static readonly OFFLINE_LAYER = {
    name: 'layer-offline',
    type: 'simple-fill',
    color: 'rgb(229, 192, 58)',
    style: 'solid',
    outline: {
      width: 0.5,
      color: [255, 255, 255],
    },
  };

  // sold out layer for apartment
  public static readonly SOLD_OUT_LAYER = {
    name: 'layer-sold-out',
    type: 'simple-fill',
    color: 'rgb(208, 92, 92)',
    style: 'solid',
    outline: {
      width: 0.5,
      color: [255, 255, 255],
    },
  };

  // sold out layer for apartment
  public static readonly BOOKED_LAYER = {
    name: 'layer-booked',
    type: 'simple-fill',
    color: 'rgb(208, 92, 92)',
    style: 'solid',
    outline: {
      width: 0.5,
      color: [255, 255, 255],
    },
  };

  // inactive, Charity, Tanmawi, Non-Beneficiary unit layer gray
  public static readonly GRAY_LAYER = {
    name: 'layer-sold-out',
    type: 'simple-fill',
    color: 'rgb(138, 151, 155)',
    style: 'solid',
    outline: {
      width: 0.5,
      color: [255, 255, 255],
    },
  };

  // for auction layers
  public static readonly LAND_AUCTION_NOT_STARTED_LAYER = {
    name: 'layer-land-auction-not-started',
    type: 'simple-fill',
    color: 'rgb(229, 192, 58)',
    style: 'forward-diagonal',
    outline: {
      width: 0.5,
      color: [255, 255, 255],
    },
  };

  public static readonly LAND_AUCTION_OPEN_LAYER = {
    name: 'layer-land-auction-open',
    type: 'simple-fill',
    color: 'rgb(0, 172, 131)',
    style: 'forward-diagonal',
    outline: {
      width: 0.5,
      color: [255, 255, 255],
    },
  };

  public static readonly LAND_AUCTION_CLOSED_EXPIRED_LAYER = {
    name: 'layer-land-auction-closed-expired',
    type: 'simple-fill',
    color: 'rgb(208, 92, 92)',
    style: 'forward-diagonal',
    outline: {
      width: 0.5,
      color: [255, 255, 255],
    },
  };

  public static readonly LEGEND_COLORS = [
    { pattern: 'primary', url: 'assets/images/legend-color-primary.png' },
    { pattern: 'warning', url: 'assets/images/legend-color-warning.png' },
    { pattern: 'danger', url: 'assets/images/legend-color-danger.png' },
  ];

  public static readonly MARKER_SYMBOL_TRANSPARENT = {
    height: '32px',
    width: '100px',
    url: '/app/assets/icons/v5/map/transparent-rectangle.svg',
  };

  public static readonly MARKER_MEGA_PROJECT_DEFAULT = {
    height: '32px',
    width: '200px',
    url: '/app/assets/icons/v5/map/mega-project-default-marker.svg',
  };

  public static readonly MARKER_MEGA_PROJECT_ACTIVE_SYMBOL = {
    height: '32px',
    width: '200px',
    url: '/app/assets/icons/v5/map/single-type-active-marker.svg',
  };

  public static readonly MARKER_SYMBOL = {
    height: '30px',
    width: '24px',
    url: '/app/assets/icons/marker.svg',
  };

  public static readonly NHC_MARKER_SYMBOL = {
    height: '30px',
    width: '24px',
    url: '/assets/icons/marker.svg',
  };

  public static readonly MULTIPLE_TYPE_PIN_iMG_SYMBOL = {
    width: '52px',
    height: '52px',
    url: '/app/assets/icons/v5/map/multiple-type-default-marker.png',
  };
  public static readonly SINGLE_TYPE_PIN_ACTIVE_SYMBOL = {
    height: '32px',
    width: '100px',
    url: '/app/assets/icons/v5/map/single-type-active-marker.svg',
  };

  public static readonly SINGLE_TYPE_PIN_VIEWED_SYMBOL = {
    height: '32px',
    width: '100px',
    url: '/app/assets/icons/v5/map/single-type-viewed-marker.svg',
  };

  public static readonly MEGA_PROJECT_PIN_VIEWED_SYMBOL = {
    height: '32px',
    width: '200px',
    url: '/app/assets/icons/v5/map/mega-project-viewed-marker.svg',
  };

  public static readonly MULTIPLE_TYPE_ACTIVE_PIN_iMG_SYMBOL = {
    width: '52px',
    height: '52px',
    url: '/app/assets/icons/v5/map/multiple-type-selected-marker.png',
  };

  public static readonly MULTIPLE_TYPE_VIEWED_PIN_iMG_SYMBOL = {
    width: '52px',
    height: '52px',
    url: '/app/assets/icons/v5/map/multiple-type-viewed-marker.png',
  };

  public static readonly graphicType = ({
    type,
    coordinates,
    attributes,
  }: {
    type: string;
    coordinates: any;
    attributes?: any;
    symbol?: any;
  }) => {
    if (type === '') return;

    switch (type) {
      case EsriMapGeometryType.POLYGON: {
        return coordinates.map((coordinate: any, idx: number) => {
          const polygon = {
            type,
            rings: [coordinate],
          };
          return new Graphic({
            geometry: polygon as __esri.GeometryProperties,
            attributes: attributes[idx],
          });
        });
      }
      case EsriMapGeometryType.POLYLINE: {
        return coordinates.map((coordinate: any, idx: number) => {
          const polyline = {
            type,
            paths: coordinate,
          };
          return new Graphic({
            geometry: polyline as __esri.GeometryProperties,
          });
        });
      }
      case EsriMapGeometryType.POINT: {
        return coordinates.map((coordinate: any, idx: number) => {
          const point = {
            type,
            longitude: coordinate.longitude,
            latitude: coordinate.latitude,
          };

          return new Graphic({
            geometry: point as __esri.GeometryProperties,
            attributes: attributes[idx],
          });
        });
      }
    }
  };
}
