import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { SakaniSessionService } from '@app/base/services/ui/sakani-session.service';
import { BootstrapBreakpoints } from '@app/enums/ui/responsive-screen-size.enum';
import { Constants, currentLang } from '@app/helpers';
import { SakaniSession } from '@app/models';
import { CookieService } from 'ngx-cookie-service';
import { AccessTokenService } from '@app/base/services/ui/access-token.service';
import { environment } from '@environment/environment';
import { SharedModule } from '@app/shared/shared.module';

@Component({
  selector: 'app-nhc-topbar',
  templateUrl: './nhc-topbar.component.html',
  styleUrls: ['./nhc-topbar.component.scss'],
  standalone: true,
  imports: [SharedModule],
})
export class NhcTopbarComponent implements OnInit {
  public session: SakaniSession | null = null;
  currentLang = currentLang();
  mobileToggle = false;
  hamburgerIcon = true;
  sakaniPath = environment.sakaniPath;
  @Input() sakaniOffplanPage = false;

  constructor(
    private sakaniSessionService: SakaniSessionService,
    private breakpointObserver: BreakpointObserver,
    private cdr: ChangeDetectorRef,
    private cookieService: CookieService,
    private accessTokenService: AccessTokenService
  ) {}

  ngOnInit(): void {
    if (this.sakaniOffplanPage) {
      this.session = this.sakaniSessionService.currentSession;
      console.log('session', this.session);
    } else {
      this.getCurrentUserInfo();
    }
    this.breakpointObserver
      .observe([BootstrapBreakpoints.xs, BootstrapBreakpoints.sm, BootstrapBreakpoints.md])
      .subscribe((result) => {
        if (result.matches) {
          this.mobileToggle = true;
        } else {
          this.mobileToggle = false;
        }
      });
    this.cdr.detectChanges();
  }

  private getCurrentUserInfo(): void {
    const userInfo = this.cookieService.get(Constants.USER_INFO_COOKIE_KEY);
    if (userInfo !== '') {
      const beneficiary = JSON.parse(userInfo);
      const userSession = this.accessTokenService.createUser(beneficiary);
      this.sakaniSessionService.setSession(userSession);
      this.session = this.sakaniSessionService.currentSession;
    } else {
      this.sakaniSessionService.setSession({});
    }
  }

  fullName(): string {
    const title = currentLang() === Constants.AR ? this.session?.user?.title_ar! : this.session?.user?.title_en!;
    const fullName = [title, this.session?.user?.first_name, this.session?.user?.last_name].join(' ');
    return fullName;
  }

  changeLanguage() {
    // detect language change
    const newLang = this.currentLang === Constants.AR ? Constants.EN : Constants.AR;
    localStorage.setItem(Constants.LOCAL_STORAGE_LANGUAGE, newLang);
    location.reload();
  }
}
