export class BookingSession {
  relationships: any = {};

  currentQueuePosition?: number;
  availableUnitsCount?: number;
  publishedAvailableUnits?: any;
  totalNumberInQueue?: number;
  selectedUnitsCount?: number;

  constructor(
    public id?: number,
    public beneficiary_national_id_number?: string,
    public booking_token?: string,
    public expired_at?: number,
    public project_id?: number,
    public project_name?: string,
    public project_type?: string,
    public queueing_token?: string,
    public status?: string,
    public unit_id?: number,
    public active_queueing?: boolean,
    public max_active_queueing_member?: number,
    public allow_joined_queue_to_start_booking?: boolean,
    public already_joined_booking_count?: number,
    public waiting_list_enable?: boolean,
    public active_financing_guarantee_journey?: boolean,
    public fetch_frc_retry_times?: number,
    public last_fetch_frc?: string,
    public is_nhc_project?: boolean
  ) {}
}
