import { CertifiedEngineeringOffice, CertifiedContractor, Design } from '.';

export class ServicePackage {
  relationships: any = {
    certified_engineering_office: { rel: 'has_one', model: CertifiedEngineeringOffice },
    certified_contractor: { rel: 'has_one', model: CertifiedContractor },
    design: { rel: 'has_one', model: Design },
  };

  certified_engineering_office?: CertifiedEngineeringOffice;
  certified_contractor?: CertifiedContractor;
  design?: Design;

  constructor(
    public id?: number,
    public name?: string,
    public description?: string,
    public status?: string,
    public certified_contractor_cr_number?: string,
    public certified_engineering_office_cr_or_lic_number?: string,
    public design_id?: number,
    public contractor_price?: string,
    public engineering_office_price?: string,
    public design_price?: string,
    public service_package_number?: string,
    public construction_price?: number
  ) {}
}
