import { DesignOrderType, PaymentChannel, PaymentOrderTypes } from '@app/enums';
import { Constants } from '@app/helpers/constants';
export class Payment {
  relationships: any = {};

  constructor(
    public id?: number,
    public order_number?: number,
    public sadad_order_requested_at?: string,
    public order_type?: string,
    public order_type_detail?: string,
    public payment_status?: string,
    public sadad_invoice_number?: string,
    public total_amount?: string,
    public title_info?: string,
    public block_number?: string,
    public sadad_invoice_expiration_at?: string,
    public sadad_invoicer_id?: number,
    public azm_order_requested_at?: string,
    public azm_invoice_expiration_at?: string,
    public booking_id?: number,
    public azm_invoice_number?: string,
    public payment_channel?: string,
    public azm_payment_reference_number?: string,
    public beneficiary_name?: string,
    public paid_at?: string,
    public canceled_at?: string,
    public azm_encrypted_invoice_number?: string,
    public auction_unit_id?: string
  ) {}

  get isDesignOrder() {
    return (
      this.order_type === DesignOrderType.InteriorDesigns ||
      this.order_type === DesignOrderType.ExteriorAndInteriorDesigns ||
      this.order_type === DesignOrderType.ExteriorDesigns
    );
  }

  get isYourOwnDesign() {
    return this.order_type === Constants.SERVICE_YOUR_OWN_DESIGN;
  }

  get isLandAuctionFee() {
    return this.order_type === PaymentOrderTypes.LAND_AUCTION_FEE;
  }

  get isUsingAzm() {
    return [
      PaymentChannel.AZM,
      PaymentChannel.AZM_EWALLET,
      PaymentChannel.AZM_SADAD,
      PaymentChannel.AZM_CREDIT_CARD,
    ].includes(this.payment_channel as PaymentChannel);
  }

  get isPayDirectly() {
    return [PaymentChannel.AZM, PaymentChannel.AZM_EWALLET].includes(this.payment_channel as PaymentChannel);
  }
}
