export class AuctionPaymentDetail {
  relationships: any = {};
  constructor(
    public id?: any,
    public payment_status?: string,
    public attachment?: string,
    public created_at?: string,
    public updated_at?: string,
    public sale_contract?: string,
    public auction_unit_id?: string,
    public contract_expired_at?: string,
    public contract_hijri_date?: string,
    public contract_status?: string,
    public rejected_at?: string,
    public rejected_reason?: string,
    public rejected_by?: string,
    public sale_contract_number?: string,
    public signed_at?: string,
    public contract_date?: string,
    public payment_channel?: string,
    public azm_invoice_expired_at?: string,
    public azm_invoice_paid_at?: string,
    public azm_invoice_number?: string,
    public azm_encrypted_invoice_number?: string,
    public snapshot_data?: any
  ) {}
}
