<div
  class="d-flex flex-column banner-message"
  appLayoutDirection
  *ngIf="warningMessage"
  [ngClass]="warningMessage.bgClass || 'bg-light-yellow'"
>
  <div class="d-flex py-2 px-3 align-items-center text-decoration-none container">
    <div class="d-flex py-3 justify-content-between w-100 align-items-center">
      <div class="d-flex align-items-center">
        <span class="svg-icon svg-icon--secondary" [ngClass]="warningMessage.icon"></span>
        <h5 [ngClass]="'fs-6 mx-3 lh-base mb-0 fw-bold'" [innerHtml]="warningMessage.title"></h5>
      </div>
    </div>
  </div>
</div>
