export class CmsVoucherBodySection {
  constructor(public title?: string, public description?: string) {}
}

export class CmsVoucherSection {
  relationships: any = {};
  constructor(
    public header_title?: string,
    public header_description?: string,
    public enable_download_app?: boolean,
    public download_app?: {
      section_title?: {
        ar: string;
        en: string;
      };
      section_subtitle?: {
        ar: string;
        en: string;
      };
      android?: string;
      ios?: string;
    },
    public body_sections: CmsVoucherBodySection[] = []
  ) {}
}
