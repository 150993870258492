import { Injectable } from '@angular/core';
import { BookingToken } from '@app/interfaces/booking-token.interface';
import { Project, SakaniSession } from '@app/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SakaniSessionService } from './sakani-session.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoadingEventService } from './loading-events.service';
import { BeneficiaryService } from '../http/beneficiary.service';
import { BookingService } from '../http/booking.service';
import { SakaniModalService } from './sakani-modal.service';
import { BookingTokenService } from './booking-token.service';
import { Beneficiary } from '@app/models';
import { ProjectUnit } from '@app/models';
import { BannerMessageInterface } from '@app/interfaces';
import moment from 'moment';
import { BlockingReason } from '@app/enums';
import { catchError, finalize, map, Observable, Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastService } from './toast.service';
import { environment } from '@environment/environment';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class OffplanBannerMessageService {
  currentBookingInfo!: BookingToken;
  public session: SakaniSession | undefined;
  beneficiary!: Beneficiary;
  bannerMessage?: BannerMessageInterface;
  warningBannerMessage?: BannerMessageInterface;
  beneficiaryBlockStatus!: any;
  isBookingExpired!: boolean;
  currentTimer = '';
  durationTimer!: number;
  projectUnit!: ProjectUnit;
  res!: any;
  currentLang = 'ar';

  showMessageBannerSubs: Subject<BannerMessageInterface | undefined> = new Subject();
  showExtraContentSub: Subject<boolean> = new Subject();

  showWarningBannerMessageSubs: Subject<BannerMessageInterface | undefined> = new Subject();

  nhcPath: string = environment.nhcPath;

  constructor(
    private sakaniSessionService: SakaniSessionService,
    private router: Router,
    private translate: TranslateService,
    private loadingEventService: LoadingEventService,
    private beneficiaryService: BeneficiaryService,
    private bookingService: BookingService,
    private toastService: ToastService,
    private bookingTokenService: BookingTokenService
  ) {
    this.sakaniSessionService.session$.pipe(untilDestroyed(this)).subscribe((value: any) => {
      this.session = value;
    });
  }

  isLogged(): boolean {
    return this.session?.isAuthenticated === true;
  }

  resetMessage() {
    this.bannerMessage = undefined;
    this.showMessageBannerSubs.next(this.bannerMessage);
  }

  setMessage(bannerMessage: BannerMessageInterface) {
    this.bannerMessage = bannerMessage;
    this.showMessageBannerSubs.next(this.bannerMessage);
  }

  resetWarningMessage() {
    this.warningBannerMessage = undefined;
    this.showWarningBannerMessageSubs.next(this.warningBannerMessage);
  }

  private getBlockReasonMessage(
    reason: BlockingReason,
    beneficiary: Beneficiary,
    project?: Project
  ): BannerMessageInterface {
    switch (reason) {
      case BlockingReason.HAS_ACTIVE_CONTRACT:
        this.bannerMessage = {
          icon: 'icon-key',
          title: this.translate.instant('PAGES.FINANCIAL_ADVISORY_FORM.BLOCKING_REASON.HAS_ACTIVE_CONTRACT.TITLE'),
          firstDescription: this.translate.instant(
            'PAGES.FINANCIAL_ADVISORY_FORM.BLOCKING_REASON.HAS_ACTIVE_CONTRACT.BODY'
          ),
          buttonText: this.translate.instant('BOOKING.BLOCK_REASON.NAVIGATE_CONTENT.MY_BOOKINGS'),
          path: '/user-profile/my-activities/my-bookings/listing',
        };
        break;
      case BlockingReason.HAS_ACTIVE_BOOKING:
        this.bannerMessage = {
          icon: 'icon-key',
          title: this.translate.instant('BOOKING.BLOCK_REASON.ALREADY_HAS_ACTIVE_BOOKING'),
          firstDescription: this.translate.instant('BOOKING.BLOCK_REASON.ALREADY_HAS_ACTIVE_BOOKING_DESCRIPTION'),
          buttonText: this.translate.instant('BOOKING.BLOCK_REASON.NAVIGATE_CONTENT.MY_BOOKINGS'),
          path: '/user-profile/my-activities/my-bookings/listing',
        };
        break;
      case BlockingReason.NOT_ELIGIBLE:
        this.bannerMessage = {
          icon: 'icon-key',
          title: this.translate.instant('BOOKING.BLOCK_REASON.IS_NOT_ELIGIBLE'),
          firstDescription: this.translate.instant('BOOKING.BLOCK_REASON.IS_NOT_ELIGIBLE_DESCRIPTION'),
          buttonText: this.translate.instant('NAVIGATION.MAIN.MY_PROFILE'),
          path: '/user-profile',
        };
        break;
      case BlockingReason.HAS_NO_ELIGIBILITY_APPLICATION:
        this.bannerMessage = {
          icon: 'icon-key',
          title: this.translate.instant('BOOKING.BLOCK_REASON.UNKNOWN_ELIGIBILITY_STATUS'),
          firstDescription: this.translate.instant('BOOKING.BLOCK_REASON.UNKNOWN_ELIGIBILITY_DESC'),
          buttonText: this.translate.instant('NAVIGATION.MAIN.CHECK_ELIGIBILITY'),
          path: '/eligibility/check',
        };
        break;
      case BlockingReason.IS_ALREADY_ALLOCATED:
        this.bannerMessage = {
          icon: 'icon-key',
          title: this.translate.instant('BOOKING.BLOCK_REASON.BENEFICIARY_IS_ALREADY_ALLOCATED'),
          firstDescription: this.translate.instant('BOOKING.BLOCK_REASON.BENEFICIARY_IS_ALREADY_ALLOCATED_DESCRIPTION'),
          buttonText: this.translate.instant('NAVIGATION.MAIN.MY_PROFILE'),
          path: '/user-profile',
        };
        break;
      case BlockingReason.HAS_FINANCIAL_APPLICATION:
        this.bannerMessage = {
          icon: 'icon-key',
          title: this.translate.instant('BOOKING.BLOCK_REASON.HAS_FINANCIAL_APPLICATION'),
          firstDescription: this.translate.instant('BOOKING.BLOCK_REASON.HAS_FINANCIAL_APPLICATION_DESCRIPTION'),
          buttonText: this.translate.instant('NAVIGATION.MAIN.MY_PROFILE'),
          path: '/user-profile',
        };
        break;
      case BlockingReason.EXIST_IN_MOHO_LIST:
        this.bannerMessage = {
          icon: 'icon-key',
          title: this.translate.instant('BOOKING.BLOCK_REASON.EXIST_IN_MOHO_LIST'),
          firstDescription: this.translate.instant('BOOKING.BLOCK_REASON.EXIST_IN_MOHO_LIST_DESCRIPTION'),
          buttonText: this.translate.instant('NAVIGATION.MAIN.MY_PROFILE'),
          path: '/user-profile',
        };
        break;
      case BlockingReason.NOT_APPLIED_FINANCIAL_ADVISORY:
        this.bannerMessage = {
          icon: 'icon-key',
          title: this.translate.instant('BOOKING.BLOCK_REASON.NOT_APPLIED_FINANCIAL_ADVISORY'),
          firstDescription: this.translate.instant('BOOKING.BLOCK_REASON.NOT_APPLIED_FINANCIAL_ADVISORY_DESCRIPTION'),
          buttonText: this.translate.instant('NAVIGATION.MAIN.MY_PROFILE'),
          path: '/user-profile',
        };
        break;
      case BlockingReason.EXCEED_FINANCIAL_RECOMMENDATION_PERIOD:
        this.bannerMessage = {
          icon: 'icon-key',
          title: this.translate.instant('BOOKING.BLOCK_REASON.EXCEED_FINANCIAL_RECOMMENDATION_PERIOD'),
          firstDescription: this.translate.instant(
            'BOOKING.BLOCK_REASON.EXCEED_FINANCIAL_RECOMMENDATION_PERIOD_DESCRIPTION'
          ),
          buttonText: this.translate.instant('NAVIGATION.MAIN.FINANCIAL_ADVISORY'),
          path: '/financial-advisory',
        };
        break;
      case BlockingReason.HAS_OPEN_APPEAL:
        this.bannerMessage = {
          icon: 'icon-key',
          title: this.translate.instant('BOOKING.BLOCK_REASON.HAS_OPEN_APPEAL_TITLE'),
          firstDescription: this.translate.instant('BOOKING.BLOCK_REASON.HAS_OPEN_APPEAL_DESCRIPTION'),
          buttonText: this.translate.instant('NAVIGATION.MAIN.MY_PROFILE'),
          path: '/user-profile',
        };
        break;
      case BlockingReason.HAS_NO_RECOMMENDATION:
        this.bannerMessage = {
          icon: 'icon-key',
          title: this.translate.instant('BOOKING.BLOCK_REASON.HAS_NO_RECOMMENDATION'),
          firstDescription: this.translate.instant('BOOKING.BLOCK_REASON.HAS_NO_RECOMMENDATION_DESC'),
          buttonText: this.translate.instant('BOOKING.BLOCK_REASON.FINANCIAL_ADVISORY_BUTTON'),
          bgClass: 'bg-light-yellow',
          externalPath: '/services/financial-advisory',
        };
        break;
      case BlockingReason.HAS_NO_AVAILABLE_UNITS:
        this.bannerMessage = {
          icon: 'icon-key',
          title: this.translate.instant('BOOKING.BLOCK_REASON.HAS_NO_AVAILABLE_UNITS'),
          firstDescription: this.translate.instant('BOOKING.BLOCK_REASON.HAS_NO_AVAILABLE_UNITS_DESCRIPTION'),
          buttonText: this.translate.instant('NAVIGATION.MAIN.MARKETPLACE'),
          path: '/marketplace',
        };
        break;
      case BlockingReason.PENDING_FOR_INELIGIBLE:
        this.bannerMessage = {
          icon: 'icon-key',
          title: this.translate.instant('BOOKING.BLOCK_REASON.PENDING_ELIGIBLE'),
          firstDescription: this.translate.instant('BOOKING.BLOCK_REASON.PENDING_ELIGIBLE_DESCRIPTION'),
          buttonText: this.translate.instant('NAVIGATION.MAIN.MY_PROFILE'),
          path: '/user-profile',
        };
        break;
      case BlockingReason.NOT_DEFINED_ELIGIBLE:
        this.bannerMessage = {
          icon: 'icon-key',
          title: this.translate.instant('BOOKING.BLOCK_REASON.NOT_DEFINED_ELIGIBLE'),
          firstDescription: this.translate.instant('BOOKING.BLOCK_REASON.NOT_DEFINED_ELIGIBLE_DESCRIPTION'),
          buttonText: this.translate.instant('NAVIGATION.MAIN.MY_PROFILE'),
          path: '/user-profile',
        };
        break;
      case BlockingReason.ELIGIBLE_HAS_ACTIVE_BOOKING:
        this.bannerMessage = {
          icon: 'icon-key',
          title: this.translate.instant('BOOKING.BLOCK_REASON.ELIGIBLE_HAS_ACTIVE_BOOKING'),
          firstDescription: this.translate.instant('BOOKING.BLOCK_REASON.ELIGIBLE_HAS_ACTIVE_BOOKING_DESCRIPTION'),
          buttonText: this.translate.instant('BOOKING.BLOCK_REASON.NAVIGATE_CONTENT.MY_BOOKINGS'),
          path: '/user-profile/my-activities/my-bookings/listing',
        };
        break;
      case BlockingReason.NON_BENE_HAS_UNPAID_BOOKING_FEE:
        this.bannerMessage = {
          icon: 'icon-key',
          title: this.translate.instant('BOOKING.BLOCK_REASON.NON_BENE_HAS_UNPAID_BOOKING_FEE', {
            projectName: beneficiary?.pending_booking_fee_invoice?.project_name,
            unitCode: beneficiary?.pending_booking_fee_invoice?.unit_code,
          }),
          disabledCollapsed: true,
        };
        break;
      case BlockingReason.EXCEED_MAXIMUM_BOOKING_PER_NON_BENE_BY_PROJECT:
        this.bannerMessage = {
          icon: 'icon-key',
          title: this.translate.instant('BOOKING.BLOCK_REASON.MAXIMUM_BOOKING_PER_NON_BENE_BY_PROJECT'),
          firstDescription: this.translate.instant(
            'BOOKING.BLOCK_REASON.MAXIMUM_BOOKING_PER_NON_BENE_BY_PROJECT_DESCRIPTION',
            {
              maximum_booking: project?.maximum_booking_per_non_beneficiary,
            }
          ),
          buttonText: this.translate.instant('BOOKING.BLOCK_REASON.NAVIGATE_CONTENT.MY_BOOKINGS'),
          path: '/user-profile/my-activities/my-bookings/listing',
        };
        break;
      case BlockingReason.MULTIPLE_BOOKING_FOR_NON_BENE_DISABLED:
        this.bannerMessage = {
          icon: 'icon-key',
          title: this.translate.instant('BOOKING.BLOCK_REASON.MULTIPLE_BOOKING_FOR_NON_BENE_DISABLED'),
          disabledCollapsed: true,
        };
        break;
      case BlockingReason.EMPTY:
        this.bannerMessage = {};
        break;
      default:
        this.bannerMessage = {
          icon: 'icon-key',
          title: this.translate.instant('COMMON.ERROR'),
          firstDescription: this.translate.instant('COMMON.SERVICE_DOWN'),
          buttonText: this.translate.instant('COMMON.TRY_AGAIN'),
          path: '/',
        };
    }
    return this.bannerMessage!;
  }

  setMessageByBeneficiaryAndProject(beneficiary: Beneficiary, project?: Project) {
    const message = this.getMessageByBeneficiaryAndProject(beneficiary, project);
    if (message) {
      this.showMessageBannerSubs.next(message);
      return message;
    }
    return;
  }

  getMessageByBeneficiaryAndProject(beneficiary: Beneficiary, project?: Project) {
    const blockReason = this.getBlockBookingReason(beneficiary, project);

    if (blockReason) {
      return this.getBlockReasonMessage(blockReason, beneficiary, project);
    } else {
      this.bannerMessage = undefined;
      return null;
    }
  }

  setMessageByRequestResponse(blockBookingReason: BlockingReason, beneficiary: Beneficiary, project: Project) {
    if (blockBookingReason) {
      const message = this.getBlockReasonMessage(blockBookingReason, beneficiary, project);
      this.showExtraContentSub.next(true);
      this.showMessageBannerSubs.next(message);
      return message;
    } else {
      this.bannerMessage = undefined;
      return null;
    }
  }

  getBlockBookingReason(beneficiary: Beneficiary, project?: Project): BlockingReason | '' {
    if (beneficiary.has_open_appeal) {
      return BlockingReason.HAS_OPEN_APPEAL;
    }

    if (beneficiary.beneficiary_application?.isUnknownStatus) {
      return BlockingReason.HAS_NO_ELIGIBILITY_APPLICATION;
    }

    // only check group eligible not in non bene
    if (beneficiary.isEligibleNotInGroupNonBeneficiary) {
      if (beneficiary.active_booking) {
        return BlockingReason.ELIGIBLE_HAS_ACTIVE_BOOKING;
      }

      if (!beneficiary.beneficiary_application) {
        return BlockingReason.HAS_NO_ELIGIBILITY_APPLICATION;
      }

      if (beneficiary.purchase_power == 0) {
        return BlockingReason.HAS_NO_RECOMMENDATION;
      }
    }

    if (beneficiary.isNonBeneficiary) {
      if (beneficiary.pending_booking_fee_invoice) {
        return BlockingReason.NON_BENE_HAS_UNPAID_BOOKING_FEE;
      }

      // check multiple booking per non-bene
      const totalNonBeneBookingsByProject = beneficiary.totalNonBeneBookingsByProject(project?.code);
      if (project?.active_multiple_bookings_for_web_mobile) {
        const maximumNonBeneBookingsByProject = project?.maximum_booking_per_non_beneficiary || 0;

        if (totalNonBeneBookingsByProject >= maximumNonBeneBookingsByProject) {
          return BlockingReason.EXCEED_MAXIMUM_BOOKING_PER_NON_BENE_BY_PROJECT;
        }
      } else {
        if (totalNonBeneBookingsByProject > 0) {
          return BlockingReason.MULTIPLE_BOOKING_FOR_NON_BENE_DISABLED;
        }
      }
    }

    // check has available units with target segments
    const hasAvailableUnits = this.hasAvailableUnits(beneficiary, project);
    if (!hasAvailableUnits && project?.bookable) {
      if (beneficiary.isEligibleNotInGroupNonBeneficiary) {
        return BlockingReason.HAS_NO_AVAILABLE_UNITS;
      }

      if (beneficiary?.isNonBeneficiary) {
        return BlockingReason.NOT_ELIGIBLE;
      }

      if (beneficiary?.isPendingForBeneficiary) {
        return BlockingReason.PENDING_FOR_INELIGIBLE;
      }

      return BlockingReason.NOT_DEFINED_ELIGIBLE;
    }

    return '';
  }

  setWarningMessage(beneficiary?: Beneficiary, project?: Project) {
    if (beneficiary?.isNonBeneficiary) {
      if (!beneficiary?.beneficiary_application && project?.bookable) {
        this.warningBannerMessage = {
          icon: 'icon-info',
          title: this.translate.instant('BOOKING.WARNING_MESSAGE.NO_BENEFICIARY_APPLICATION', {
            username: beneficiary.last_name,
          }),
          firstDescription: this.translate.instant('BOOKING.WARNING_MESSAGE.NO_BENEFICIARY_APPLICATION_DESC'),
        };
      }
    }
    this.showWarningBannerMessageSubs.next(this.warningBannerMessage);
    return this.warningBannerMessage;
  }

  hasAvailableUnits(beneficiary: Beneficiary, project?: Project) {
    if (beneficiary?.isEligibleNotInGroupNonBeneficiary) {
      return project?.units_statistic_data?.target_segment_info?.has_beneficiary_unit;
    }

    if (beneficiary?.isNonBeneficiary) {
      return project?.units_statistic_data?.target_segment_info?.has_non_bene_unit;
    }

    return false;
  }

  setNotLoggedBlockMessage() {
    this.showMessageBannerSubs.next(this.getNotLoggedBlockMessage);
    return this.getNotLoggedBlockMessage;
  }

  get getNotLoggedBlockMessage() {
    this.bannerMessage = {
      icon: 'icon-key',
      title: this.translate.instant('PROJECT_PAGE.NOT_LOGGED_IN'),
      firstDescription: this.translate.instant('PROJECT_PAGE.NOT_LOGGED_DESCRIPTION'),
      buttonText: this.translate.instant('TENANCY_AUTHENTICATION_PAGE.LOGIN_IN'),
      bgClass: 'bg-light-yellow',
      path: '/authentication/login',
    };
    return this.bannerMessage;
  }

  private setBookingTimerBlockMessage() {
    this.bannerMessage = {
      icon: 'icon-clock',
      title: this.translate.instant('PROJECT_PAGE.BOOKING_TIMER.TITLE'),
      firstDescription: this.translate.instant('PROJECT_PAGE.BOOKING_TIMER.FIRST_DESC'),
      buttonText: this.translate.instant('USER_PROFILE.MY_BOOKING.CANCEL_BOOKING_BTN'),
      bgClass: 'bg-light-yellow',
      forCase: 'mainJourney',
    };

    this.showMessageBannerSubs.next(this.bannerMessage);
    return this.bannerMessage;
  }

  private setBookingTimerNoReserveUnitMsg() {
    this.bannerMessage = {
      title: this.translate.instant('PROJECT_PAGE.BOOKING_TIMER.TITLE'),
      icon: 'icon-clock',
      firstDescription: this.translate.instant('PROJECT_PAGE.BOOKING_TIMER.NO_UNIT'),
      buttonText: this.translate.instant('USER_PROFILE.MY_BOOKING.CANCEL_BOOKING_BTN'),
      bgClass: 'bg-light-yellow',
      forCase: 'mainJourney',
    };

    this.showMessageBannerSubs.next(this.bannerMessage);
    return this.bannerMessage;
  }

  private setBookingJourneyNoReserveUnitMsg() {
    this.bannerMessage = {
      title: this.translate.instant('PROJECT_PAGE.BOOKING_TIMER.TITLE'),
      icon: 'icon-clock',
      firstDescription: this.translate.instant('PROJECT_PAGE.BOOKING_TIMER.OTHER_FLOW_NO_UNIT', {
        project_name: this.currentProjectName(),
      }),
      buttonText: this.translate.instant('PROJECT_PAGE.BOOKING_TIMER.OTHER_FLOW_CONT_BUTTON'),
      secondBtnText: this.translate.instant('USER_PROFILE.MY_BOOKING.CANCEL_BOOKING_BTN'),
      bgClass: 'bg-light-yellow',
      forCase: 'outOfJourney',
      allowStartBooking: true,
    };
    this.showMessageBannerSubs.next(this.bannerMessage);
    return this.bannerMessage;
  }

  private setBookingJourneyBlockMessage() {
    this.bannerMessage = {
      title: this.translate.instant('PROJECT_PAGE.BOOKING_TIMER.TITLE'),
      icon: 'icon-clock',
      firstDescription: this.translate.instant('PROJECT_PAGE.BOOKING_TIMER.OTHER_FLOW_FIRST_DESC', {
        project_name: this.currentProjectName(),
      }),
      buttonText: this.translate.instant('PROJECT_PAGE.BOOKING_TIMER.OTHER_FLOW_CONT_BUTTON'),
      secondBtnText: this.translate.instant('USER_PROFILE.MY_BOOKING.CANCEL_BOOKING_BTN'),
      bgClass: 'bg-light-yellow',
      forCase: 'outOfJourney',
    };
    this.showMessageBannerSubs.next(this.bannerMessage);
    return this.bannerMessage;
  }

  private getBookingExpiredOtherBlockMessage() {
    this.bannerMessage = {
      title: this.translate.instant('PROJECT_PAGE.BOOKING_TIMER.EXPIRED'),
      icon: 'icon-clock',
      firstDescription: this.translate.instant('PROJECT_PAGE.BOOKING_TIMER.OTHER_DESC_EXPIRED', {
        project_name: this.currentProjectName(),
      }),
      buttonText: this.translate.instant('PROJECT_PAGE.BOOKING_TIMER.BACK_BUTTON'),
      secondBtnText: this.translate.instant('PROJECT_PAGE.BOOKING_TIMER.DISMISS_BUTTON'),
      bgClass: 'bg-light-red',
      bgCollapsedClass: 'banner-light-red',
      forCase: 'expiredOutJourney',
      allowStartBooking: true,
    };
    this.showMessageBannerSubs.next(this.bannerMessage);
    return this.bannerMessage;
  }

  private setBookingExpiredBlockMessage() {
    this.bannerMessage = {
      title: this.translate.instant('PROJECT_PAGE.BOOKING_TIMER.EXPIRED'),
      icon: 'icon-clock',
      firstDescription: this.translate.instant('PROJECT_PAGE.BOOKING_TIMER.DESC_EXPIRED'),
      buttonText: this.translate.instant('PROJECT_PAGE.BOOKING_TIMER.DISMISS_BUTTON'),
      forCase: 'expiredInJourney',
      bgClass: 'bg-light-red',
      bgCollapsedClass: 'banner-light-red',
      allowStartBooking: true,
    };

    this.showMessageBannerSubs.next(this.bannerMessage);
    return this.bannerMessage;
  }

  private setQueueingInFlowMessage() {
    const loadingIcon = '<div class="spinner-border mx-1 spinner-border-sm" role="status"></div>';
    let currentPos: any = loadingIcon;
    if (
      this.bookingTokenService.currentBookingInfo?.currentQueuePosition &&
      this.bookingTokenService.currentBookingInfo?.currentQueuePosition > 0
    ) {
      currentPos = this.bookingTokenService.currentBookingInfo?.currentQueuePosition;
    }

    this.bannerMessage = {
      title: this.translate.instant('PROJECT_PAGE.PROJECT_QUEUEING.TITLE', {
        position: currentPos,
      }),
      icon: 'icon-queue',
      firstDescription: this.translate.instant('PROJECT_PAGE.PROJECT_QUEUEING.IN_JOURNEY_DESC'),
      buttonText: this.translate.instant('PROJECT_PAGE.BOOKING_FLOW.LEAVE_QUEUE'),
      forCase: 'queueInJourney',
      bgCollapsedClass: 'bg-gray-blue-25',
      bgClass: 'bg-gray-blue-25',
    };

    this.showMessageBannerSubs.next(this.bannerMessage);
    return this.bannerMessage;
  }

  private setQueueingOutFlowMessage() {
    const loadingIcon = '<div class="spinner-border mx-1 spinner-border-sm" role="status"></div>';
    let currentPos: any = loadingIcon;
    if (
      this.bookingTokenService.currentBookingInfo?.currentQueuePosition &&
      this.bookingTokenService.currentBookingInfo?.currentQueuePosition > 0
    ) {
      currentPos = this.bookingTokenService.currentBookingInfo?.currentQueuePosition;
    }

    this.bannerMessage = {
      title: this.translate.instant('PROJECT_PAGE.PROJECT_QUEUEING.TITLE', {
        position: currentPos,
      }),
      icon: 'icon-queue',
      firstDescription: this.translate.instant('PROJECT_PAGE.PROJECT_QUEUEING.OUT_JOURNEY_DESC', {
        project_name: this.currentProjectName(),
      }),
      buttonText: this.translate.instant('PROJECT_PAGE.BOOKING_TIMER.BACK_BUTTON'),
      secondBtnText: this.translate.instant('PROJECT_PAGE.BOOKING_FLOW.LEAVE_QUEUE'),
      forCase: 'queueOutJourney',
      bgClass: 'bg-gray-blue-25',
      bgCollapsedClass: 'bg-gray-blue-25',
    };

    this.showMessageBannerSubs.next(this.bannerMessage);
    return this.bannerMessage;
  }

  currentProjectName(): string {
    return this.bookingTokenService.currentProjectName();
  }

  currentProjectId(): number {
    return this.bookingTokenService.currentProjectId();
  }

  currentUnitId(): number {
    return this.bookingTokenService.currentUnitId();
  }

  isNhcProject(): boolean {
    return this.bookingTokenService.isNhcProject();
  }

  updateBannerBooking(): BannerMessageInterface | undefined {
    this.isBookingExpired = this.bookingTokenService.isExpired();
    if (this.hasBookingToken()) {
      return this.handleBookingTokenMessage();
    }

    if (this.bookingTokenService.hasQueueingToken()) {
      return this.handleQueueingTokenMessage();
    }

    return;
  }

  handleQueueingTokenMessage(): BannerMessageInterface | undefined {
    if (this.isOnCurrentProjectBooking()) {
      return (this.bannerMessage = this.setQueueingInFlowMessage());
    } else {
      return (this.bannerMessage = this.setQueueingOutFlowMessage());
    }
  }

  handleBookingTokenMessage(): BannerMessageInterface {
    if (this.isOnCurrentProjectBooking()) {
      if (this.isBookingExpired) {
        return (this.bannerMessage = this.setBookingExpiredBlockMessage());
      }
      if (this.currentUnitId()) {
        return (this.bannerMessage = this.setBookingTimerBlockMessage());
      }
      if (!this.currentUnitId()) {
        return (this.bannerMessage = this.setBookingTimerNoReserveUnitMsg());
      }
    } else {
      if (this.isBookingExpired) {
        return (this.bannerMessage = this.getBookingExpiredOtherBlockMessage());
      }
      if (this.currentUnitId()) {
        return (this.bannerMessage = this.setBookingJourneyBlockMessage());
      }
      if (!this.currentUnitId()) {
        return (this.bannerMessage = this.setBookingJourneyNoReserveUnitMsg());
      }
    }
    return this.bannerMessage!;
  }

  durationInSeconds(): any {
    if (!this.bookingTokenService.hasToken()) {
      return null;
    }
    const expiredAt = this.bookingTokenService.expiredAt() * 1000;
    // const expiresBookingTokenAt = moment.utc(this.bookingTokenService.expiredAt()).local().format('YYYY-MM-DD HH:mm:ss');
    const expiresBookingTokenAt = moment.utc(new Date(expiredAt)).local().format('YYYY-MM-DD HH:mm:ss');
    const currentTime = moment.utc(new Date()).local().format('YYYY-MM-DD HH:mm:ss');
    const diff = moment.duration(moment(expiresBookingTokenAt).diff(moment(currentTime)));
    this.durationTimer = diff.asSeconds();
    return this.durationTimer;
  }

  hasBookingToken(): boolean {
    return this.bookingTokenService.hasToken();
  }

  isOnCurrentProjectBooking(projectId?: number | string): boolean {
    const bookingProjectId = projectId || this.currentProjectId();
    return (
      (this.isOffplanProjectPath && this.matchCurrentProjectBooking(bookingProjectId)) ||
      (window.location.pathname.indexOf('/units/') > 0 && !this.currentUnitId()) ||
      window.location.pathname.indexOf('units/' + this.currentUnitId()) > 0 ||
      window.location.pathname.indexOf('disclaimer') > 0 ||
      window.location.pathname.indexOf('financial-recommendations-certificate') > 0 ||
      window.location.pathname.indexOf('booking-summary') > 0
    );
  }

  updateCurrentTimmer(timer?: any) {
    this.currentTimer = timer;
  }

  callCancelBooking(projectId: number): Observable<any> {
    return this.bookingService
      .offplanCancelBookingJourney(projectId)
      .pipe(
        untilDestroyed(this),
        catchError((error) => {
          this.loadingEventService.complete();
          throw error;
        })
      )
      .pipe(
        map((res: any) => {
          if (res.result === 'fail') {
            this.toastService.showError(
              this.translate.instant(`BOOKING.BLOCK_REASON.${res.result.toUpperCase()}_DESCRIPTION`)
            );
          } else {
            const projectId = this.bookingTokenService?.currentProjectId();
            this.bookingTokenService.clear();
            if (this.isOnCurrentProjectBooking(projectId)) {
              this.returnToProject(projectId);
            }
          }
        })
      );
  }

  removeBookingToken() {
    this.loadingEventService.start();
    this.beneficiaryService
      .removeBookingToken()
      .pipe(
        finalize(() => this.loadingEventService.complete()),
        untilDestroyed(this)
      )
      .subscribe();
  }

  dismiss(): void {
    if (this.isOnCurrentProjectBooking()) {
      this.returnToProject();
    }
    this.removeBookingToken();
    this.bookingTokenService.clear();
  }

  continueBooking(): void {
    this.bookingTokenService.loadBookingSession();
    if (this.bookingTokenService.hasBookingUnit()) {
      if (this.isNhcProject()) {
        this.urlRedirection('/booking/offplan/disclaimer');
      } else {
        this.router.navigate(['/booking', 'offplan', 'disclaimer']);
      }
    } else {
      this.returnToProject();
    }
  }

  returnToProject(projectId?: string | number): void {
    const bookingProjectId = projectId || this.currentProjectId();
    if (this.isOffplanProjectPath && this.matchCurrentProjectBooking(bookingProjectId)) {
      location.reload();
    } else {
      if (this.isNhcProject()) {
        this.urlRedirection(`/offplan-projects/${projectId || this.currentProjectId()}`);
      } else {
        this.router.navigate(['/offplan-projects/', projectId || this.currentProjectId()]);
      }
    }
  }

  get isOffplanProjectPath(): boolean {
    return location.pathname.indexOf('/offplan-projects/') > 0;
  }

  matchCurrentProjectBooking(projectId?: string | number): boolean {
    return location.pathname.split('/')[3]?.toString() === projectId?.toString();
  }

  urlRedirection(path: string): void {
    window.location.href = `${this.nhcPath}${path}`;
  }
}
