import { AnnexStatus } from '@app/enums';

export class Annex {
  relationships: any = {};
  constructor(
    public sale_contract_annex_id?: number,
    public annex_status?: AnnexStatus,
    public annex_reject_reason?: string,
    public document?: string
  ) {}
}
