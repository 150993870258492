import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { zeroPad } from '@app/helpers';
import { timer } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';

interface TimeData {
  secondsToDday: number;
  minutesToDday: number;
}

// TODO: countdown
@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
})
export class CountdownComponent implements OnInit, OnChanges {
  // the countdown total in seconds
  @Input() durationInSeconds!: number;
  @Input() countdownClass = '';
  @Output() countdownExpired = new EventEmitter<boolean>();
  @Output() currentTimmer = new EventEmitter<string>();

  timerStr = '';
  private targetDate!: Date;

  constructor() {}

  ngOnInit(): void {
    this.initCountdown();
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (
      changes['durationInSeconds'] &&
      changes['durationInSeconds'].previousValue != changes['durationInSeconds'].currentValue
    ) {
      this.initCountdown();
    }
  }

  initCountdown() {
    this.targetDate = new Date(new Date().getTime() + this.durationInSeconds * 1000);
    timer(0, 1000)
      .pipe(
        map((x) => this.calcDateDiff(this.targetDate)),
        takeWhile((t) => {
          if (t.minutesToDday >= 0 || t.secondsToDday >= 0) {
            return true;
          }
          this.countdownExpired.emit(true);
          return false;
        })
      )
      .subscribe((t) => {
        this.timerStr = `${zeroPad(t.minutesToDday, 2)}:${zeroPad(t.secondsToDday, 2)}`;
        this.currentTimmer.emit(this.timerStr);
      });
  }

  calcDateDiff(endDate: Date): TimeData {
    const dDay = endDate.valueOf();

    const milliSecondsInASecond = 1000;
    const minutesInAnHour = 60;
    const secondsInAMinute = 60;

    const timeDifference = dDay - Date.now();

    if (timeDifference === 0) {
      return {
        secondsToDday: 0,
        minutesToDday: 0,
      };
    }

    const minutesToDday = Math.floor((timeDifference / (milliSecondsInASecond * minutesInAnHour)) % secondsInAMinute);

    const secondsToDday = Math.floor(timeDifference / milliSecondsInASecond) % secondsInAMinute;

    return { secondsToDday, minutesToDday };
  }
}
