export class LandSubsidyContract {
  relationships: any = {};

  constructor(
    public id?: string,
    public booking_id?: string,
    public contract_number?: string,
    public verification_method?: string,
    public status?: string,
    public digital_fingerprint?: string,
    public signed_at?: string,
    public signed_contract_file?: string,
    public contract_appendix_file?: string,
    public final_appendix_file?: string,
    public final_contract_file?: string,
    public cadastral_form_file?: string,
    public sadad_invoice_expiration_at?: Date,
    public sadad_invoice_number?: string
  ) {}
}
