import { Auction } from './auction';

export class BiddingDetail {
  relationships = {
    auction_unit: { rel: 'has_one', model: Auction },
  };
  constructor(
    public id?: any,
    public unit_code?: string,
    public project_code?: string,
    public auction_unit_id?: string,
    public beneficiary_name?: string,
    public beneficiary_national_id_number?: string,
    public beneficiary_phone_number?: string,
    public bid_price?: number,
    public created_at?: string,
    public time_left?: string,
    public auction_unit?: Auction
  ) {}
}
