export class CertifiedEngineeringOffice {
  relationships = {};

  constructor(
    public id?: number,
    public cr_or_lic_number?: string,
    public entity_name?: string,
    public is_published?: boolean,
    public status_details?: string,
    public status_details_code?: string,
    public status_details_message?: string,
    public data?: string,
    public status_code?: string,
    public status_name?: string,
    public items_count?: number,
    public error_details?: string,
    public result_item?: string,
    public entity_segel_no?: string,
    public entity_type?: string,
    public manager_name?: string,
    public delegation_start_date?: string,
    public delegation_end_date?: string,
    public classification_grade?: string,
    public facility_name?: string,
    public facility_size?: number,
    public region?: string,
    public city?: string,
    public contact_number?: string,
    public meter_price?: number,
    public logo?: string,
    public logo_ext?: string,
    public cr_number?: string,
    public license_number?: string,
    public email?: string,
    public supervision_max_price?: number,
    public supervision_min_price?: number,
    public turkey_max_price?: number,
    public turkey_min_price?: number,
    public design_contractor_min_price?: number,
    public design_contractor_max_price?: number,
    public membership_number?: number,
    public membership_status?: string
  ) {}
}
