import { ImageSliderDataInterface } from '@app/shared/interfaces';
import { Designer, DesignProfile, Discount } from '.';

export class Design {
  relationships = {};

  constructor(
    public id?: number,
    public name?: string,
    public brief_description?: Text,
    public code?: string,
    public price?: number,
    public land_width?: number,
    public land_length?: number,
    public number_of_floors?: number,
    public number_of_bathrooms?: number,
    public number_of_bedrooms?: number,
    public first_floor_area?: number,
    public second_floor_area?: number,
    public third_floor_area?: number,
    public structure_drawing?: boolean,
    public architectural_drawing?: boolean,
    public electrical?: boolean,
    public plumping?: boolean,
    public conditioning?: boolean,
    public front_face_design?: boolean,
    public landscape?: boolean,
    public internal_design?: boolean,
    public publish?: boolean,
    public publishment?: string,
    public last_published_version?: string,
    public designer_id?: number,
    public designer?: Designer,
    public media?: any,
    public first_published_date?: string,
    public fees?: any,
    public land_area?: number,
    public number_of_rooms?: number,
    public main_kitchen_width?: number,
    public main_kitchen_length?: number,
    public master_bedroom_width?: number,
    public master_bedroom_length?: number,
    public living_room_width?: number,
    public living_room_length?: number,
    public dining_room_width?: number,
    public dining_room_length?: number,
    public elevator?: boolean,
    public nanny_room?: boolean,
    public driver_room?: boolean,
    public swimming_pool?: boolean,
    public storage_area?: boolean,
    public balcony?: boolean,
    public laundry_room?: boolean,
    public car_parking?: boolean,
    public supported_land_areas?: any[],
    public construction_offer_lowest_price?: number,
    public last_published_media?: any,
    public discount?: Discount,
    public new_fees?: any,
    public is_best_seller?: boolean,
    public building_code?: any,
    public design_profile_id?: number,
    public design_profile?: DesignProfile,
    public views_count?: number,
    public published_interior_design_count?: number
  ) {}

  get landArea() {
    return this.land_width! * this.land_length!;
  }

  get detailUrl() {
    return `/housing-designs/${this.id}`;
  }

  get designMain() {
    return this.media?.design_main?.attributes;
  }

  get brochure() {
    return this.media?.brochure?.attributes;
  }

  get floorPlans() {
    const ret: any = [];

    if (this.media?.first_floor_plan?.attributes?.url) {
      this.media.first_floor_plan.attributes.description = 'first_floor_plan';
      ret.push(this.media?.first_floor_plan?.attributes);
    }
    if (this.media?.second_floor_plan?.attributes?.url) {
      this.media.second_floor_plan.attributes.description = 'second_floor_plan';
      ret.push(this.media?.second_floor_plan?.attributes);
    }
    if (this.media?.third_floor_plan?.attributes?.url) {
      this.media.third_floor_plan.attributes.description = 'third_floor_plan';
      ret.push(this.media?.third_floor_plan?.attributes);
    }

    return ret;
  }

  get gallery() {
    if (this.media?.gallery) {
      return this.media.gallery.map((item: any) => item.attributes);
    }
    return [];
  }

  get video() {
    return this.media?.video?.attributes;
  }

  get frontendImage() {
    return this.media?.frontend?.attributes;
  }

  get totalPrice() {
    return this.new_fees?.total_amount;
  }

  get totalPriceBeforeDiscount() {
    return this.new_fees?.price_snapshot_data?.total_price_before_discount;
  }

  get discountEndDate(): any {
    return this.discount?.end_date?.split(' ')[0];
  }

  get discountStartDate(): any {
    return this.discount?.start_date?.split(' ')[0];
  }

  get fixedLayout() {
    if (this.media?.fixed_layout) {
      return this.media.fixed_layout.map((item: any) => item.attributes);
    }
    return [];
  }

  get designGallery(): ImageSliderDataInterface[] {
    const rawGallery = this.media?.gallery || [];

    const designMain: ImageSliderDataInterface = {
      sourceUrl: this.designMain?.url || null,
      thumbUrl: this.designMain?.thumbnail_url || this.designMain?.url,
    };

    const gallery = rawGallery?.map((g: any) => {
      const data: ImageSliderDataInterface = {
        sourceUrl: g?.attributes?.url,
        thumbUrl: g?.attributes?.thumbnail_url || g?.attributes?.url,
      };
      return data;
    });
    const projectImages = [designMain, ...gallery].filter((i) => i.sourceUrl);
    return projectImages;
  }

  get designMainImage() {
    return {
      sourceUrl: this.media?.design_main?.attributes?.url || 'assets/images/empty-placeholder.jpeg',
      thumbUrl: this.media?.design_main?.attributes?.thumbnail_url || 'assets/images/empty-placeholder.jpeg',
      originUrl: this.media?.design_main?.attributes?.url || 'assets/images/empty-placeholder.jpeg',
    };
  }
}
