import { Injectable } from '@angular/core';
import { ApiService } from '@app/base/services/http/api.service';
import { Endpoints } from '@app/helpers/endpoints';
import { Observable, Subject, timer } from 'rxjs';
import { concatMap, map, takeUntil } from 'rxjs/operators';

export type PollingOptions = {
  intervalTimeOut: number;
  maxCounter: number;
};

@Injectable({
  providedIn: 'root',
})
export class QueryPollingService {
  constructor(private api: ApiService) {}

  // default polling time = 2 minutes (call 20 times, per 6 second)
  polling(topic: string, requestId: string, intervalTimeOut?: number, maxCounter?: number): Observable<any> {
    intervalTimeOut = intervalTimeOut || 6000;
    maxCounter = maxCounter || 10;

    const subs = new Subject<any>();
    let counter = 0;

    timer(1500, intervalTimeOut)
      .pipe(
        concatMap(() => this.fetchData(topic, requestId, subs)),
        takeUntil(subs)
      )
      .subscribe((_) => {
        counter += 1;
        if (counter >= maxCounter) {
          subs.error(new Error('timeout'));
          subs.unsubscribe();
        }
      });

    return subs;
  }

  fetchData(topic: string, requestId: string, subs: Subject<any>): Observable<any> {
    return this.api
      .apiGet(
        Endpoints.QUERRY_POLLING_PATH,
        undefined,
        { topic: topic, request_id: requestId },
        'application/json',
        false,
        false
      )
      .pipe(
        map((res: any) => {
          if (res.cqrs_status != 'waiting') {
            subs.next(res.data);
            subs.unsubscribe();
          }
        })
      );
  }

  fetchAuctionData(requestId: string, subs: Subject<any>): Observable<any> {
    return this.api
      .apiGet(
        Endpoints.QUERRY_POLLING_PATH,
        undefined,
        { topic: 'auction', request_id: requestId },
        'application/json',
        false,
        false
      )
      .pipe(
        map((res: any) => {
          if (res.cqrs_status != 'waiting') {
            subs.next(res.data);
          }
        })
      );
  }

  checkBidderRegistrationPayment(requestId: string, subs: Subject<any>): Observable<any> {
    return this.api
      .apiGet(
        Endpoints.QUERRY_POLLING_PATH,
        undefined,
        { topic: 'auction_fee_payment', request_id: requestId },
        'application/json',
        false,
        false
      )
      .pipe(
        map((res: any) => {
          if (res.cqrs_status != 'waiting') {
            subs.next(res.data);
          }
        })
      );
  }
}
