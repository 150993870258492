import { AppealStatus, AppealState } from '@app/enums/appeal.enum';

export class Appeal {
  relationships: any = {};
  constructor(
    public id?: number,
    public appeal_status?: AppealStatus,
    public appeal_explanation?: string,
    public appeal_sent_at?: string,
    public appeal_approved_at?: string,
    public beneficiary_national_id_number?: string,
    public beneficiary_application_id?: string,
    public created_at?: string,
    public updated_at?: string,
    public assign_to_username?: string,
    public assign_to_user_group?: string,
    public state?: AppealState,
    public requested_document_at?: string,
    public rejected_at?: string,
    public sub_appeals?: SubAppeal[]
  ) {}

  get canResubmit(): boolean {
    if (!this.appeal_status) {
      return false;
    }
    const hasNotAllowedStatus =
      [AppealStatus.PENDING, AppealStatus.UNDER_PROCESSING, AppealStatus.REJECTED].indexOf(this.appeal_status!) > 0;
    const hasInvalidState = this.appeal_status === AppealStatus.NEW && this.state === AppealState.SUCCESS;
    return !hasNotAllowedStatus || !hasInvalidState;
  }

  get isUnderProcessing() {
    return this.appeal_status === AppealStatus.UNDER_PROCESSING;
  }

  get isNew() {
    return this.appeal_status === AppealStatus.NEW;
  }

  get isRequiredDocs() {
    return this.appeal_status === AppealStatus.DOCUMENT_REQUIRED;
  }

  get isRejected() {
    return this.appeal_status === AppealStatus.REJECTED;
  }
}

export class SubAppeal {
  constructor(
    public id: number,
    public appeal_explanation: string,
    public appeal_id: number,
    public crm_explanation: string,
    public details: string,
    public justification_code: string,
    public rule_key: string,
    public status: string
  ) {}
}
